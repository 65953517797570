define('spyglass/components/nav-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appSettings: Ember.inject.service(),
    router: Ember.inject.service(),

    tagName: 'nav',
    classNameBindings: ['isOpen'],

    isOpen: false,
    isOnCompaniesPage: Ember.computed.equal('router.currentRouteName', 'companies'),
    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOpen');
      },
      close: function close() {
        this.set('isOpen', false);
        return true;
      }
    }
  });
});