define('spyglass/models/company', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    externalId: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    website: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    externalCreatedAt: (0, _attr.default)('date'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    businessModel: (0, _attr.default)('string'),
    sourcedOn: (0, _attr.default)('date'),
    logoUrl: (0, _attr.default)('string'),
    tags: (0, _attr.default)(),
    investmentTheme: (0, _attr.default)(),
    relevantLines: (0, _attr.default)(),
    contact: (0, _attr.default)(),

    operatingGeography: (0, _attr.default)('string'),
    partnershipOpportunity: (0, _attr.default)('string'),
    riskCommentary: (0, _attr.default)('string'),

    meta: (0, _attr.default)(),

    references: (0, _relationships.hasMany)('reference', { async: false }),
    reviews: (0, _relationships.hasMany)('review', { async: false }),
    teamNotes: (0, _relationships.hasMany)('team-note', { async: false }),
    uploads: (0, _relationships.hasMany)('upload', { async: false }),

    tagsList: Ember.computed('tags.[]', function () {
      return this.tags.join(', ');
    }),
    investmentThemeList: Ember.computed('investmentTheme.[]', function () {
      return this.investmentTheme.join(', ');
    }),
    relevantLinesList: Ember.computed('relevantLines.[]', function () {
      return this.relevantLines.join(', ');
    }),
    location: Ember.computed('city', 'state', function () {
      return [this.city, this.state].compact().join(', ');
    }),
    websiteWithProtocol: Ember.computed('website', function () {
      var url = this.website;
      if (url) {
        url = url.toLowerCase();
        if (url.substr(0, 4) !== 'http') {
          url = 'http://' + url;
        }
        return url;
      }
    }),
    analogsList: Ember.computed('analogs', function () {
      var analogs = this.analogs || '';
      return analogs.split(',').map(function (x) {
        return x.trim();
      });
    }),
    starRating: Ember.computed('weightedAverageScore', function () {
      var score = this.weightedAverageScore;
      return Math.ceil(score / 2);
    }),
    barChartData: Ember.computed('defenseScore', 'financialScore', 'managementScore', 'marketScore', 'productScore', 'strategicFit', function () {
      return [{ label: 'DEFENSE', value: this.defenseScore }, { label: 'FINANCIAL', value: this.financialScore }, { label: 'MGMT.', value: this.managementScore }, { label: 'MARKET', value: this.marketScore }, { label: 'PRODUCT', value: this.productScore }, { label: 'STRAT. FIT', value: this.strategicFit }];
    })
  });
});