define('spyglass/companies/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    appSettings: Ember.inject.service(),

    queryParams: {
      page: { refreshModel: true },
      search: { refreshModel: true },
      themes: { refreshModel: true },
      lines: { refreshModel: true },
      statuses: { refreshModel: true },
      countries: { refreshModel: true }
    },
    model: function model(params) {
      var query = {
        page: { number: params.page, size: 20 }
      };
      if (params.search.length > 2) {
        query.search = params.search;
      }
      if (params.themes || params.lines) {
        query.tags = (params.themes || []).concat(params.lines || []);
      }
      if (params.statuses) {
        query.status = params.statuses;
      }
      if (params.countries) {
        query.country = params.countries;
      }
      return {
        companiesPromise: this.store.query('company', query)
      };
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);
      controller.set('isLoading', true);
      model.companiesPromise.then(function (companies) {
        var tags = _this.store.peekAll('tag');
        controller.setProperties({
          companies: companies,
          totalPages: companies.get('meta.total_pages'),
          total: companies.get('meta.total'),
          isLoading: false,
          availableThemes: tags.filterBy('type', 'investment_theme').mapBy('label').sort(),
          availableLines: tags.filterBy('type', 'relevant_line').mapBy('label').sort()
        });
      });
    }
  });
});