define('spyglass/edit-user/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var user = this.store.findRecord('user', params.user_id, { reload: true });
      var companySubscriptions = user.get('companySubscriptions');

      return Ember.RSVP.hash({ user: user, companySubscriptions: companySubscriptions });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var user = model.user;
      var sections = this.store.peekAll('uploadSection').sortBy('position');
      var tags = model.user.get('tagSubscriptions').mapBy('tag');
      var companies = user.get('companySubscriptions').mapBy('company');
      controller.setProperties({ user: user, sections: sections, tags: tags, companies: companies });
    }
  });
});