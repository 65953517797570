define('spyglass/dashboard/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    current: Ember.inject.service(),

    model: function model() {
      var companySubscriptions = this.store.findAll('company-subscription', { reload: true });
      var portfolioCompanies = this.store.query('company', {
        stage: ['Portfolio Company Fund 1', 'Portfolio Company Fund 2', 'Portfolio Company Fund 3']
      });
      var uploads = this.store.query('upload', {
        page: { size: 5 }, upload_section_id: null
      });
      return Ember.RSVP.hash({ companySubscriptions: companySubscriptions, portfolioCompanies: portfolioCompanies, uploads: uploads });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var tagSubscriptions = this.get('current.user.tagSubscriptions') || [];
      controller.set('tags', tagSubscriptions.mapBy('tag'));
      controller.set('companies', model.companySubscriptions.mapBy('company'));
      controller.set('portfolioCompanies', model.portfolioCompanies.sortBy('name'));
      controller.set('uploads', model.uploads);
    }
  });
});