define('spyglass/components/companies-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    current: Ember.inject.service(),

    tagName: '',
    isLoading: false
  });
});