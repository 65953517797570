define('spyglass/components/pagination-links/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    page: 1,
    lastPage: Ember.computed.oneWay('totalPages'),
    nextPage: Ember.computed('page', function () {
      return this.page + 1;
    }),
    prevPage: Ember.computed('page', function () {
      return this.page - 1;
    }),
    prevPageDisabled: Ember.computed.lte('page', 1),
    nextPageDisabled: Ember.computed('page', 'totalPages', function () {
      return this.page >= this.totalPages;
    })
  });
});