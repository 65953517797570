define('spyglass/models/tag', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    label: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    companyCount: (0, _attr.default)('number'),
    uploadsCount: (0, _attr.default)('number'),
    thirtyDayCompanyCount: (0, _attr.default)('number'),
    sixtyDayCompanyCount: (0, _attr.default)('number'),
    ninetyDayCompanyCount: (0, _attr.default)('number'),

    companyCountForPeriod: function companyCountForPeriod() {
      var period = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 90;

      switch (period) {
        case 30:
          return this.thirtyDayCompanyCount;
        case 60:
          return this.sixtyDayCompanyCount;
        case 90:
          return this.ninetyDayCompanyCount;
      }
    }
  });
});