define('spyglass/components/ember-initials/gravatar/component', ['exports', 'ember-initials/components/gravatar'], function (exports, _gravatar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gravatar.default;
    }
  });
});