define('spyglass/models/team-note', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    company: (0, _relationships.belongsTo)('company'),
    user: (0, _relationships.belongsTo)('user', { async: false }),

    productionInfo: (0, _attr.default)('string'),
    vendorTeamInfo: (0, _attr.default)('string'),
    abilityToWorkWithBluePlans: (0, _attr.default)('string'),
    otherInfo: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),

    title: Ember.computed('user.{fullName,companyName,email}', function () {
      var user = this.user;
      if (user.get('fullName') && user.get('companyName')) {
        return user.get('fullName') + ' | ' + user.get('companyName');
      } else if (user.get('fullName')) {
        return user.get('fullName');
      } else {
        return user.get('email');
      }
    })
  });
});