define('spyglass/company/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    current: Ember.inject.service(),

    model: function model(params) {
      var company = this.store.findRecord('company', params.company_id);
      return Ember.RSVP.hash({ company: company });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('company', model.company);
      var tags = model.company.get('tags');
      if (tags) {
        this.store.query('upload', { tags: tags }).then(function (uploads) {
          return controller.set('uploads', uploads);
        });
      }
      this.store.query('upload', { company_id: model.company.id }).then(function (uploads) {
        return controller.set('files', uploads);
      });
      var subscription = this.get('current.user.companySubscriptions').find(function (sub) {
        return sub.belongsTo('company').id() === model.company.id;
      });
      controller.set('subscription', subscription);
    },
    resetController: function resetController(controller) {
      controller.set('uploads', []);
    },

    actions: {
      print: function print() {
        window.print();
      }
    }
  });
});