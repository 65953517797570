define('spyglass/services/ajax', ['exports', 'ember-ajax/services/ajax', 'spyglass/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    host: _environment.default.API_HOST,
    session: Ember.inject.service(),

    headers: Ember.computed('session.data.authenticated.access_token', {
      get: function get() {
        var headers = {};
        var token = this.get('session.data.authenticated.access_token');
        if (token) {
          headers['Authorization'] = 'Bearer ' + token;
        }
        return headers;
      }
    })
  });
});