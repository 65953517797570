define('spyglass/components/submit-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    attributeBindings: ['type', 'disabled'],
    classNames: ['btn'],
    type: 'submit',
    label: 'Save',
    isSaving: false,
    isInvalid: false,

    disabled: Ember.computed.or('isInvalid', 'isSaving'),
    onClick: function onClick() {},
    click: function click() {
      this.onClick();
    }
  });
});