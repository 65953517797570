define('spyglass/components/tag-filter-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    label: Ember.computed.oneWay('tag'),
    tagsArray: Ember.computed('tag', function () {
      return [this.tag];
    })
  });
});