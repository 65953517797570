define('spyglass/dashboard/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    appSettings: Ember.inject.service(),
    current: Ember.inject.service()
  });
});