define('spyglass/components/role-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    userRoles: Object.freeze([{ value: 'regular', label: 'Regular User' }, { value: 'admin', label: 'Admin' }]),
    selectedRole: Ember.computed('userRoles.[]', 'user.role', function () {
      return this.userRoles.findBy('value', this.get('user.role'));
    }),
    actions: {
      setRole: function setRole(role) {
        this.user.set('role', role.value);
      }
    }
  });
});