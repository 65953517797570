define('spyglass/components/delete-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    label: 'Delete',
    message: 'Are you sure?',
    isDeleting: false,
    onConfirm: function onConfirm() {}
  });
});