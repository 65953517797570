define('spyglass/components/range-picker/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    label: 'Select a date range',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.resetRange();
    },
    onSelect: function onSelect() {},
    resetRange: function resetRange() {
      var start = this.startDate && (0, _moment.default)(this.startDate);
      var end = this.endDate && (0, _moment.default)(this.endDate);
      this.set('range', { start: start, end: end });
    },

    actions: {
      done: function done(dropdown) {
        var range = this.range;
        if (range.start && range.end) {
          this.onSelect(range);
        } else {
          this.resetRange();
        }
        dropdown.actions.close();
      }
    }
  });
});