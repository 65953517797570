define('spyglass/models/review', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    company: (0, _relationships.belongsTo)('company'),
    user: (0, _relationships.belongsTo)('user', { async: false }),

    descriptionAccuracy: (0, _attr.default)('string'),
    financialResults: (0, _attr.default)('string'),
    clinicalResults: (0, _attr.default)('string'),
    memberExperience: (0, _attr.default)('string'),
    implementationExperience: (0, _attr.default)('string'),
    clientServiceExperience: (0, _attr.default)('string'),
    reasonToUse: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),

    title: Ember.computed('user.{fullName,companyName,email}', function () {
      var user = this.user;
      if (user.get('fullName') && user.get('companyName')) {
        return user.get('fullName') + ' | ' + user.get('companyName');
      } else if (user.get('fullName')) {
        return user.get('fullName');
      } else {
        return user.get('email');
      }
    })
  });
});