define('spyglass/login/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    flashMessages: Ember.inject.service(),

    resetController: function resetController(controller) {
      controller.set('errorMessage', null);
    },
    deactivate: function deactivate() {
      this.flashMessages.clearMessages();
    }
  });
});