define('spyglass/references/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    isLoading: false,

    isShowingPagination: Ember.computed.gt('totalPages', 1)
  });
});