define('spyglass/components/tags-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: '',
    label: 'Tags',
    isLoading: false,
    countPeriods: Object.freeze([30, 60, 90]),
    countPeriod: 90,
    maxCount: Ember.computed('tags.[]', 'countPeriod', function () {
      switch (this.countPeriod) {
        case 30:
          return Math.max.apply(Math, _toConsumableArray(this.tags.mapBy('thirtyDayCompanyCount')));
        case 60:
          return Math.max.apply(Math, _toConsumableArray(this.tags.mapBy('sixtyDayCompanyCount')));
        case 90:
          return Math.max.apply(Math, _toConsumableArray(this.tags.mapBy('ninetyDayCompanyCount')));
      }
    })
  });
});