define('spyglass/trends/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    current: Ember.inject.service(),
    sortedTags: Ember.computed.sort('model', 'tagsSorting'),
    tagsSorting: Object.freeze(['companyCount:desc'])
  });
});