define('spyglass/company/team-note/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    current: Ember.inject.service(),

    actions: {
      close: function close() {
        this.transitionToRoute('company');
      },
      delete: function _delete() {
        var _this = this;

        this.model.destroyRecord().then(function () {
          return _this.send('close');
        });
      }
    }
  });
});