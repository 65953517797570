define('spyglass/new-portfolio-upload/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    appSettings: Ember.inject.service(),

    queryParams: ['sectionId'],
    actions: {
      afterSave: function afterSave() {
        this.transitionToRoute('portfolio', { queryParams: { sectionId: this.get('model.uploadSection.id') } });
      }
    }
  });
});