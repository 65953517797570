define('spyglass/application/route', ['exports', 'ember-data', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _emberData, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    current: Ember.inject.service(),
    error: Ember.inject.service(),

    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('uploadSections', this.store.peekAll('uploadSection'));
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.current.load().then(function (user) {
        return user && _this.error.identify(user);
      }, function (error) {
        _this.session.invalidate();
        throw error;
      });
    },

    actions: {
      error: function error(_error) {
        if (_error instanceof _emberData.default.NotFoundError) {
          this.intermediateTransitionTo('404', '');
          return false;
        } else {
          return true;
        }
      }
    }
  });
});