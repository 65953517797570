define('spyglass/components/company-profile/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['sizeClass'],
    size: 'medium',

    sizeClass: Ember.computed('size', function () {
      return {
        medium: this.styleNamespace + '__md',
        large: this.styleNamespace + '__lg'
      }[this.size];
    })
  });
});