define('spyglass/components/feedback-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: 'positive',
    onchange: function onchange() {},

    positiveClass: Ember.computed('value', function () {
      if (this.value === 'positive') {
        return this.styleNamespace + '__active';
      }
    }),
    negativeClass: Ember.computed('value', function () {
      if (this.value === 'negative') {
        return this.styleNamespace + '__active';
      }
    }),
    actions: {
      switch: function _switch(value) {
        if (this.value !== value) {
          this.onchange(value);
        }
      }
    }
  });
});