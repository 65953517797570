define('spyglass/components/avatar-company/component', ['exports', 'spyglass/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: ['sizeClass'],
    size: 'medium',

    sizeClass: Ember.computed('size', function () {
      return {
        small: this.styleNamespace + '__sm',
        medium: this.styleNamespace + '__md',
        large: this.styleNamespace + '__lg'
      }[this.size];
    }),
    backgroundColor: Ember.computed(function () {
      return _environment.default.ACTIVE_BACKGROUND_COLOR;
    }),
    textColor: Ember.computed(function () {
      return _environment.default.PRIMARY_COLOR;
    })
  });
});