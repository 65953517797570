define('spyglass/models/upload', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    key: (0, _attr.default)('string'),
    contentType: (0, _attr.default)('string'),
    size: (0, _attr.default)('number'),
    date: (0, _attr.default)('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    tags: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [];
      } }),

    uploadSection: (0, _relationships.belongsTo)('upload-section'),
    company: (0, _relationships.belongsTo)('company')
  });
});