define('spyglass/profile/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-buffered-proxy/proxy'], function (exports, _authenticatedRouteMixin, _proxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    current: Ember.inject.service(),

    model: function model() {
      return this.get('current.user');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var userProxy = _proxy.default.create({ content: model });
      controller.set('userProxy', userProxy);
    }
  });
});