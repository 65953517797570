define('spyglass/adapters/application', ['exports', 'ember-data', 'spyglass/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-inflector'], function (exports, _emberData, _environment, _dataAdapterMixin, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.API_HOST,

    authorize: function authorize(xhr) {
      var _get = this.get('session.data.authenticated'),
          access_token = _get.access_token;

      xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    pathForType: function pathForType(modelName) {
      var underscored = Ember.String.underscore(modelName);
      return (0, _emberInflector.pluralize)(underscored);
    }
  });
});