define('spyglass/services/app-settings', ['exports', 'spyglass/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    current: Ember.inject.service(),

    name: Ember.computed('current.user.isLimited', function () {
      if (this.get('current.user.isLimited')) {
        return 'Knowledge Base';
      } else {
        return _environment.default.APP_NAME;
      }
    }),
    aboutYoutube: Ember.computed(function () {
      return _environment.default.APP_ABOUT_YOUTUBE;
    }),
    uploadsSectionTitle: Ember.computed(function () {
      return _environment.default.UPLOADS_SECTION_TITLE || 'Portfolio';
    }),
    lastUpdatedFilterField: Ember.computed(function () {
      return _environment.default.LAST_UPDATED_FILTER_FIELD || 'external_updated_at';
    }),
    showChartTooltip: Ember.computed(function () {
      return _environment.default.SHOW_CHART_TOOLTIP === 'true';
    })
  });
});