define('spyglass/services/current', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    metrics: Ember.inject.service(),

    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.store.queryRecord('user', { me: true }).then(function (user) {
          _this.set('user', user);
          _this.metrics.identify('Intercom', {
            email: user.email,
            created_at: user.createdAt,
            name: user.fullName,
            user_id: user.id,
            role: user.role,
            company_name: user.companyName
          });
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});