define('spyglass/models/reference', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    company: (0, _relationships.belongsTo)('company'),
    user: (0, _relationships.belongsTo)('user', { async: false }),

    experience: (0, _attr.default)('string', { defaultValue: 'positive' }),
    feedbackAreas: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [];
      } }),
    createdAt: (0, _attr.default)('date'),

    isPositive: Ember.computed.equal('experience', 'positive')
  });
});