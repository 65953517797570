define('spyglass/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    current: Ember.inject.service(),

    isShowingNavBar: Ember.computed('session.isAuthenticated', 'currentPath', function () {
      return this.get('session.isAuthenticated') && this.currentPath !== 'password-reset';
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        this.session.invalidate();
      },
      searchCompanies: function searchCompanies(searchTerm) {
        this.transitionToRoute('companies', { queryParams: { search: searchTerm, page: 1 } });
      }
    }
  });
});