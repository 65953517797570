define('spyglass/new-portfolio-upload/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var section = this.store.peekRecord('upload-section', params.sectionId);
      return this.store.createRecord('upload', { uploadSection: section });
    }
  });
});