define('spyglass/company/new-review/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    current: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var user = this.get('current.user');
      if (this.modelFor('company').company.get('reviews').isAny('user', user)) {
        this.transitionTo('company');
      }
    },
    model: function model() {
      return this.store.createRecord('review', {
        user: this.get('current.user'),
        company: this.modelFor('company').company
      });
    },

    actions: {
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
      }
    }
  });
});