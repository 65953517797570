define('spyglass/companies/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    filters: Ember.inject.service('company-filters'),
    current: Ember.inject.service(),

    queryParams: ['page', 'search', { themes: { type: 'array' } }, { lines: { type: 'array' } }, { statuses: { type: 'array' } }, { countries: { type: 'array' } }],
    page: 1,
    search: '',
    init: function init() {
      this._super.apply(this, arguments);
      this.tags = [];
      this.statuses = [];
      this.countries = [];
      this.availableThemes = [];
      this.availableLines = [];
    },

    isLoading: false,
    emptyArray: Object.freeze([]), // used to clear array filters in link-to
    isShowingPagination: Ember.computed.gt('totalPages', 1),
    hasAnyFilters: Ember.computed('statuses.[]', 'themes.[]', 'lines.[]', 'countries.[]', function () {
      return this.get('statuses.length') || this.get('themes.length') || this.get('lines.length') || this.get('countries.length');
    }),
    actions: {
      searchTermChanged: function searchTermChanged(searchTerm) {
        this.setProperties({
          search: searchTerm,
          page: 1
        });
      },
      setFilter: function setFilter(filter, value) {
        this.set(filter, value);
        this.set('page', 1);
      },
      deleteFilter: function deleteFilter(filter, value) {
        this.get(filter).removeObject(value);
        this.set('page', 1);
      },
      clearFilters: function clearFilters() {
        this.setProperties({
          statuses: [],
          themes: [],
          lines: [],
          countries: [],
          page: 1
        });
      }
    }
  });
});