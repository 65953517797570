define('spyglass/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    email: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    companyName: (0, _attr.default)('string'),
    role: (0, _attr.default)('string', { defaultValue: 'regular' }),
    loginCount: (0, _attr.default)('number'),
    lastLoggedInAt: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    jobTitle: (0, _attr.default)('string'),
    phoneNumber: (0, _attr.default)('string'),

    companySubscriptions: (0, _relationships.hasMany)('company-subscription', { async: false }),
    tagSubscriptions: (0, _relationships.hasMany)('tag-subscription', { async: false }),
    permissions: (0, _relationships.hasMany)('permission', { async: false }),

    isAdmin: Ember.computed.equal('role', 'admin'),
    isLimited: Ember.computed.equal('role', 'limited'),
    displayName: Ember.computed('fullName', 'email', function () {
      return this.fullName || this.email;
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return [this.firstName, this.lastName].compact().join(' ');
    })
  });
});