define('spyglass/router', ['exports', 'spyglass/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    metrics: Ember.inject.service(),

    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    didTransition: function didTransition() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', function () {
        _this.metrics.trackPage('GoogleAnalytics');
      });
    }
  });

  Router.map(function () {
    this.route('login');
    this.route('forgot-password');
    this.route('password-reset', { path: '/password-reset/:token' });
    this.route('password-set', { path: '/password-set/:token' });

    this.route('about');
    this.route('dashboard');
    this.route('companies');
    this.route('company', { path: '/companies/:company_id' }, function () {
      this.route('new-reference');
      this.route('reference', { path: '/references/:reference_id' });
      this.route('new-review');
      this.route('review', { path: '/reviews/:review_id' });
      this.route('edit-review', { path: '/edit-review/:review_id' });
      this.route('new-team-note');
      this.route('team-note', { path: '/team-note/:team_note_id' });
      this.route('edit-team-note', { path: '/edit-team-note/:team_note_id' });
      this.route('file-upload');
    });
    this.route('trends');
    this.route('lines');
    this.route('trend-uploads');
    this.route('new-trend-upload', { path: '/trend-uploads/new' });
    this.route('edit-trend-upload', { path: '/trend-uploads/:upload_id' });
    this.route('trend', { path: '/trends/:tag_id' });
    this.route('portfolio');
    this.route('new-portfolio-upload', { path: '/portfolio/new' });
    this.route('edit-portfolio-upload', { path: '/portfolio/:upload_id' });
    this.route('users');
    this.route('invite-user', { path: '/users/new' });
    this.route('edit-user', { path: '/users/:user_id' }, function () {
      this.route('subscriptions');
    });
    this.route('reviews');
    this.route('references');
    this.route('profile', function () {
      this.route('trend-subscriptions');
      this.route('company-subscriptions');
    });

    this.route('components');

    this.route('404', { path: '*path' });
  });

  exports.default = Router;
});