define('spyglass/users/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      page: { refreshModel: true },
      search: { refreshModel: true },
      sort: { refreshModel: true }
    },
    model: function model(params) {
      var query = {
        page: { number: params.page, size: 20 }
      };
      if (params.search.length > 2) {
        query.search = params.search;
      }
      if (params.sort) {
        query.sort = params.sort;
      }
      return {
        usersPromise: this.store.query('user', query)
      };
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('isLoading', true);
      model.usersPromise.then(function (users) {
        controller.setProperties({
          users: users,
          totalPages: users.get('meta.total_pages'),
          total: users.get('meta.total'),
          isLoading: false
        });
      });
    }
  });
});