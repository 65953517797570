define('spyglass/users/controller', ['exports', 'spyglass/config/environment', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _environment, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_fileSaver.default, {
    ajax: Ember.inject.service(),
    current: Ember.inject.service(),
    session: Ember.inject.service(),

    queryParams: ['page', 'search'],
    page: 1,
    search: '',
    isLoading: false,
    sort: null,

    isShowingPagination: Ember.computed.gt('totalPages', 1),

    actions: {
      delete: function _delete(user) {
        user.destroyRecord();
      },
      export: function _export() {
        var _this = this;

        this.set('isExporting', true);
        this.ajax.request(_environment.default.API_HOST + '/users/export', {
          method: 'GET',
          dataType: 'blob',
          processData: false
        }).then(function (content) {
          _this.set('isExporting', false);
          return _this.saveFileAs('users.csv', content, 'csv');
        });
      },
      searchTermChanged: function searchTermChanged(searchTerm) {
        this.setProperties({
          search: searchTerm,
          page: 1,
          sort: null
        });
      }
    }
  });
});