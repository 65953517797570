define('spyglass/components/trend-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    tagRecord: Ember.computed('tag', function () {
      var tagLabel = this.tag;
      var tags = this.store.peekAll('tag');
      return tags.findBy('label', tagLabel);
    }),
    disabled: Ember.computed('tagRecord', function () {
      return Ember.isEmpty(this.tagRecord);
    })
  });
});