define('spyglass/components/sort-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    sortValue: Ember.computed('isAsc', function () {
      if (this.isAsc) {
        return '-' + this.sortField;
      } else {
        return this.sortField;
      }
    }),
    isAsc: Ember.computed('sort', 'sortField', function () {
      return this.sort === this.sortField;
    }),
    isDesc: Ember.computed('sort', 'sortField', function () {
      return this.sort === '-' + this.sortField;
    })
  });
});