define('spyglass/edit-user/subscriptions/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var user = model.user;
      var tags = model.user.get('tagSubscriptions').mapBy('tag');
      var companies = user.get('companySubscriptions').mapBy('company');
      controller.setProperties({ user: user, tags: tags, companies: companies });
    }
  });
});