define('spyglass/company/file-upload/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var company = this.modelFor('company').company;
      return this.store.createRecord('upload', { company: company });
    },

    actions: {
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
      }
    }
  });
});