define('spyglass/components/date-picker/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    label: 'Select a date',
    date: null,
    value: Ember.computed('date', function () {
      var date = this.date;
      if (date) {
        return (0, _moment.default)(date).format('M/D/YYYY');
      }
    }),
    onSelect: function onSelect() {},

    actions: {
      select: function select(dropdown, selection) {
        dropdown.actions.close();
        this.onSelect(selection.date);
      }
    }
  });
});