define('spyglass/services/company-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    availableStatuses: ['Active', 'Sold / Acquire', 'Failed / Shut Down'],
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    countries: ['Argentina', 'Australia', 'Belgium', 'Bermuda', 'Brazil', 'Canada', 'China', 'Croatia', 'France', 'Germany', 'Hungary', 'India', 'Ireland', 'Israel', 'Italy', 'Kenya', 'Luxembourg', 'Netherlands', 'New Zealand', 'Norway', 'Philippines', 'Singapore', 'South Africa', 'Spain', 'Switzerland', 'Turkey', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Zimbabwe']
  });
});