define('spyglass/instance-initializers/honeybadger', ['exports', 'spyglass/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(applicationInstance) {
    var errorService = applicationInstance.lookup('service:error');

    if (window.Honeybadger && _environment.default.HONEYBADGER_API_KEY) {
      window.Honeybadger.configure({
        api_key: _environment.default.HONEYBADGER_API_KEY,
        environment: _environment.default.HONEYBADGER_ENVIRONMENT,
        onerror: true,
        revision: _environment.default.SOURCE_VERSION
      });

      Ember.onerror = function (error) {
        errorService.notify(error);
      };

      Ember.RSVP.configure('onerror', function (error) {
        errorService.notify(error);
      });
    }
  }

  exports.default = {
    name: 'honeybadger',
    initialize: initialize
  };
});