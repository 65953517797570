define('spyglass/helpers/format-currency', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCurrency = formatCurrency;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function formatCurrency(_ref) {
    var _ref3 = _slicedToArray(_ref, 1),
        value = _ref3[0];

    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref2$sign = _ref2.sign,
        sign = _ref2$sign === undefined ? '$' : _ref2$sign,
        _ref2$precision = _ref2.precision,
        precision = _ref2$precision === undefined ? 0 : _ref2$precision;

    return value ? (0, _formatMoney.default)(value, sign, precision) : '—';
  }

  exports.default = Ember.Helper.helper(formatCurrency);
});