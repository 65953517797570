define('spyglass/portfolio/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      page: { refreshModel: true },
      sectionId: { refreshModel: true },
      search: { refreshModel: true }
    },
    model: function model(params) {
      var sectionId = params.sectionId || this.store.peekAll('uploadSection').sortBy('position')[0].id;
      var query = {
        page: { number: params.page, size: 20 },
        upload_section_id: sectionId
      };
      if (params.search.length > 2) {
        query.search = params.search;
      }
      return {
        uploadsPromise: this.store.query('upload', query)
      };
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('isLoading', true);
      model.uploadsPromise.then(function (uploads) {
        controller.setProperties({
          uploads: uploads,
          totalPages: uploads.get('meta.total_pages'),
          total: uploads.get('meta.total'),
          isLoading: false
        });
      });

      var sections = this.store.peekAll('uploadSection').sortBy('position');
      controller.set('sections', sections);
      if (!controller.sectionId) {
        controller.set('sectionId', sections[0].id);
      }
    }
  });
});