define('spyglass/components/form-group/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['hasError', 'hasSuccess'],
    hasFeedback: false,

    hasError: Ember.computed('hasFeedback', 'errors.[]', function () {
      return this.hasFeedback && !Ember.isEmpty(this.errors);
    }),
    hasSuccess: Ember.computed('hasFeedback', 'errors.[]', function () {
      return this.hasFeedback && Ember.isEmpty(this.errors);
    }),
    formattedError: Ember.computed('errors.[]', function () {
      return this.errors[0];
    }),

    init: function init() {
      this.errors = [];
      this.set('api', { inputId: 'input-' + Ember.guidFor(this) });
      this._super.apply(this, arguments);
    },
    focusOut: function focusOut() {
      this.set('hasFeedback', true);
    }
  });
});