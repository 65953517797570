define('spyglass/components/scrollable-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    containerClass: Ember.computed('styleNamespace', function () {
      return this.styleNamespace + '__container';
    }),
    overlayClass: Ember.computed('styleNamespace', function () {
      return this.styleNamespace + '__overlay';
    }),
    wrapperClass: Ember.computed('styleNamespace', function () {
      return this.styleNamespace + '__wrapper';
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        document.body.classList.add('scrollable-modal-showing');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.body.classList.remove('scrollable-modal-showing');
    }
  });
});