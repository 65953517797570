define('spyglass/components/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['tab'],
    tab: 'all',
    company: Object.freeze({
      name: 'Visible',
      description: 'Bacon ipsum dolor amet ball tip flank filet mignon meatloaf biltong ham. Tongue ham hock tail, jowl picanha short ribs prosciutto shoulder sirloin shankle spare ribs pork loin alcatra swine.',
      logoUrl: 'https://logo.clearbit.com/visible.vc'
    }),
    companyWithoutLogo: Object.freeze({
      name: 'No Logo',
      starRating: 4
    }),
    user: Object.freeze({
      fullName: 'Philip Fry',
      displayName: 'Philip Fry',
      email: 'fry@example.com'
    }),
    filterOptions: Object.freeze(['foo', 'bar', 'baz', 'qux']),
    barChartData: Object.freeze([{ label: 'BAR 1', value: 70 }, { label: 'BAR 2', value: 85 }, { label: 'BAR 3', value: 60 }, { label: 'BAR 4', value: 30 }, { label: 'BAR 5', value: 90 }]),
    lineChartData: Object.freeze([{ date: '2015-10-01', value: 65 }, { date: '2015-11-01', value: 20 }, { date: '2015-12-01', value: 80 }, { date: '2016-01-01', value: 65 }, { date: '2016-02-01', value: 65 }, { date: '2016-03-01', value: 20 }, { date: '2016-04-01', value: 80 }, { date: '2016-05-01', value: 65 }, { date: '2016-06-01', value: 20 }, { date: '2016-07-01', value: 80 }, { date: '2016-08-01', value: 35 }, { date: '2016-09-01', value: 45 }, { date: '2016-10-01', value: 65 }, { date: '2016-11-01', value: 20 }, { date: '2016-12-01', value: 80 }, { date: '2017-01-01', value: 35 }, { date: '2017-02-01', value: 45 }]),
    feedback: 'positive',
    actions: {
      testAction: function testAction() {
        var message = arguments[0] || 'Test Action';
        alert(message);
      }
    }
  });
});