define('spyglass/company/new-team-note/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    current: Ember.inject.service(),

    model: function model() {
      return this.store.createRecord('team-note', {
        user: this.get('current.user'),
        company: this.modelFor('company').company
      });
    },

    actions: {
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
      }
    }
  });
});