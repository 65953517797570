define('spyglass/components/line-chart/component', ['exports', 'd3-selection', 'd3-scale', 'd3-array', 'd3-axis', 'd3-time', 'd3-shape', 'd3-format', 'moment', 'd3-transition', 'd3-ease'], function (exports, _d3Selection, _d3Scale, _d3Array, _d3Axis, _d3Time, _d3Shape, _d3Format, _moment, _d3Transition, _d3Ease) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    width: 400,
    height: 150,
    color: '#FFB119',
    animationSpeed: 500,

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.scheduleOnce('render', this, this.redraw);
    },
    redraw: function redraw() {
      var element = this.$('svg')[0];
      element.innerHTML = '';
      if (this.get('data.length')) {
        var data = this.data;
        var extremes = (0, _d3Array.extent)(data, function (dataPoint) {
          return (0, _moment.default)(dataPoint.date);
        });
        var range = _d3Time.timeYear.range(extremes[0], extremes[1].clone().add(1, 'year'));
        var paddedData = range.map(function (date) {
          var dataPoint = data.find(function (dataPoint) {
            return (0, _moment.default)(dataPoint.date).year() === date.getFullYear();
          });
          var value = dataPoint && dataPoint.value || 0;
          return { date: date, value: value };
        });

        this.draw(paddedData);
      }
    },
    draw: function draw(data) {
      var margin = { top: 10, right: 15, bottom: 20, left: 20 };
      var width = this.width - margin.left - margin.right;
      var height = this.height - margin.top - margin.bottom;
      var extremes = (0, _d3Array.extent)(data, function (dataPoint) {
        return dataPoint.value;
      });

      var yScale = (0, _d3Scale.scaleLinear)().domain(extremes).domain([0, extremes[1]]).rangeRound([height, 0]);
      var xScale = (0, _d3Scale.scaleTime)().domain((0, _d3Array.extent)(data, function (dataPoint) {
        return Date.parse(dataPoint.date);
      })).rangeRound([0, width]);
      var svg = (0, _d3Selection.select)(this.$('svg')[0]).append('g').attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');

      var xAxis = (0, _d3Axis.axisBottom)().scale(xScale);
      var step = data.length > 12 ? 5 : 1;
      svg.append('g').attr('class', 'axis x-axis').attr('transform', 'translate(0,' + height + ')').call(xAxis.ticks(_d3Time.timeYear.every(step)));

      var lineFunction = (0, _d3Shape.line)().curve(_d3Shape.curveLinear).x(function (dataPoint) {
        return xScale(Date.parse(dataPoint.date));
      }).y(function (dataPoint) {
        return yScale(dataPoint.value);
      });

      var path = svg.append('path').datum(data).attr('fill', 'none').attr('stroke', this.color).attr('stroke-linejoin', 'round').attr('stroke-linecap', 'round').attr('stroke-width', 1.5).attr('d', lineFunction);

      var yAxis = (0, _d3Axis.axisLeft)().scale(yScale).tickValues([extremes[1]]).tickFormat((0, _d3Format.format)('d'));
      svg.append('g').attr('class', 'axis y-axis').call(yAxis);

      var totalLength = path.node().getTotalLength();
      path.attr('stroke-dasharray', totalLength + ' ' + totalLength).attr('stroke-dashoffset', totalLength).transition().duration(this.animationSpeed).ease(_d3Ease.easeLinear).attr('stroke-dashoffset', 0);
    }
  });
});