define('spyglass/components/star-rating/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    rating: 0,

    stars: Ember.computed('rating', function () {
      var rating = this.rating;
      return [1, 2, 3, 4, 5].map(function (number) {
        return rating >= number ? 'yellow' : 'gray';
      });
    })
  });
});